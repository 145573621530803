







































import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import * as common from '@/common/gamesList';
import * as schema from '@/gql/schema';
import gql from 'graphql-tag';

@Component
export default class GameRatingForm extends Vue {
  @Prop(String) microgameId!: string;
  private isSubmitting = false;
  private inputs = {
    isFavorited: 'Yes',
    difficulty: '1',
    comment: '',
  };

  @Emit('success')
  private emitSubmitSuccess() {
    return;
  }

  private async submit() {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    try {
      const input: schema.AddMicrogameRatingInput = {
        microgameId: this.microgameId,
        comment: this.inputs.comment,
        difficulty: parseInt(this.inputs.difficulty),
        isFavorited: this.inputs.isFavorited === 'Yes',
      };
      const result = await this.$apollo.mutate<schema.Mutation>({
        variables: {
          input,
        },
        mutation: gql`
          mutation RatingForm_addMicrogameRating(
            $input: AddMicrogameRatingInput!
          ) {
            addMicrogameRating(input: $input) {
              id
              comment
              createdAt
              difficulty
              editedAt
              isFavorited
            }
          }
        `,
      });

      if (result.errors?.length) {
        throw result.errors[0];
      }
      this.emitSubmitSuccess();
    } catch (err) {
      alert(`${err?.message} || ${err}`);
    }

    this.isSubmitting = false;
  }
}
