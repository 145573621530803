























































import { Component, Vue } from 'vue-property-decorator';
import { getRouteMetadata, RoutePath } from '../../common/routes';

@Component({
  metaInfo() {
    const routeMetadata = getRouteMetadata(
      this.$route.matched[this.$route.matched.length - 1].path as RoutePath,
      this.$route.params,
      this.$route.query
    );
    return {
      title: routeMetadata.title,
    };
  },
})
export default class Play extends Vue {
  private readonly controlSchemes = [
    {
      title: 'XBOX Controller',
      directionKeys: 'Left Thumbstick',
      actionKeys: 'A / B',
    },
    {
      title: 'Keyboard Scheme 1',
      directionKeys: 'WSAD',
      actionKeys: 'K / L',
    },
    {
      title: 'Keyboard Scheme 2',
      directionKeys: 'Arrow Up, Arrow Down, Arrow Left, Arrow Right',
      actionKeys: 'Z / X',
    },
  ];

  private isShowingControls = false;
  private isFocused = false;
  private isSmall = true;

  // private get playerSize() {
  //   if (this) {
  //     return 540;
  //   } else {
  //     return 540 * 2;
  //   }
  // }
}
