














































































































































































































import LaroldImg from '@/components/LaroldImg.vue';
import GameRatingForm from './components/GameRatingForm.vue';
import { Component, Vue } from 'vue-property-decorator';
import * as schema from '@/gql/schema';
import gql from 'graphql-tag';
import { getRouteMetadata, RoutePath, routeName } from '../../common';
import Cart from '@/components/Cart.vue';

@Component({
  components: {
    LaroldImg,
    GameRatingForm,
    Cart,
  },
  metaInfo() {
    const routeMetadata = getRouteMetadata(
      this.$route.matched[this.$route.matched.length - 1].path as RoutePath,
      this.$route.params,
      this.$route.query
    );
    return {
      title: routeMetadata.title,
    };
  },
  apollo: {
    microgameByGameId: {
      fetchPolicy: 'cache-and-network',
      skip() {
        return !this.game;
      },
      variables() {
        return {
          gameId: this.game.id,
        };
      },
      query: gql`
        query Game_microgame($gameId: String!) {
          microgameByGameId(gameId: $gameId) {
            id
            hasMyRating
            ratings {
              id
              comment
              difficulty
              editedAt
              createdAt
              isFavorited
              author {
                id
                displayName
              }
            }
            stats {
              id
              wins
              losses
              totalPlays
              lastPlayedTimestamp
              winRatio
              difficultySlices {
                difficulty
                totalPlays
                wins
                losses
                winRatio
              }
            }
          }
        }
      `,
    },
  },
})
export default class Game extends Vue {
  private microgameByGameId!: schema.Microgame;

  private get microgame() {
    return this.microgameByGameId;
  }

  private activeTabIndex = 0;

  private get game() {
    return this.$lwMeta.findGame(this.gameSlug as string);
  }

  private get browseByAuthorRoute() {
    return {
      name: routeName('browse-by-author'),
      params: {
        author: '',
      },
    };
  }

  private get gameSlug() {
    return this.$route.params.game;
  }

  private get prompt() {
    return this?.game?.prompt || '';
  }

  private get cartLabelSrc() {
    return this.game ? `/games/${this.game.id}.png` : '';
  }

  private get displayName() {
    return this.game?.displayName || '';
  }

  private get authorName() {
    return this.game?.authors || '';
  }

  private get dateAdded() {
    return this.game?.dataAdded || '';
  }

  private get gameDuration() {
    return this.game?.timeSeconds || 0;
  }

  private get hasMyRating() {
    return this.microgame?.hasMyRating || false;
  }

  private get hasRatings() {
    return this.microgame?.ratings?.length > 0 || false;
  }

  private get description() {
    return this?.game?.description || [];
  }

  private get howToPlay() {
    return this?.game?.howToPlay || [];
  }

  private get timesFavorited() {
    let val = 0;
    this.microgame?.ratings?.forEach((i) => {
      val += i.isFavorited ? 1 : 0;
    });
    return val;
  }

  private get difficultyRating() {
    let val = 0;
    this.microgame?.ratings?.forEach((i) => {
      val += i.difficulty;
    });
    val /= this.microgame?.ratings?.length || 1;
    return val;
  }

  private get ratingsCount() {
    return this.microgame?.ratings?.length || 0;
  }

  private get credits() {
    const credits = this.game?.credits || [];
    return credits;
  }

  private get galleryRelativeLink() {
    return `/play?gallery_id=${this.game?.id}`;
  }

  // private get myRating(){
  //   return this.microgame?.ratings.find(i => i.author?.)
  // }

  private get authors() {
    if (this.game) {
      return this.game.authorIds.map((id, i) => {
        return {
          id,
          displayName: this.game?.authors[i],
        };
      });
    }
    return [];
  }

  private onRatingSubmitSuccess() {
    this.$apollo.queries.microgameByGameId.refetch();
  }
}
